<template>
  <v-container class="pagina-integracao">
    <DialogConfirmation
      title="ATENÇÃO!"
      tag="delete"
      :width="850"
      :show="dialogAviso"
      @close="fecharDialogAviso()"
      @confirm="confirmarDialogAviso()"
    >
      <template v-slot:body>
        <div class="aviso-dialog">
          <p>ESTA OPERAÇÃO LEVARÁ ALGUNS MINUTOS.</p>
          <p>AO SER INICIADA NÃO PODERÁ SER CANCELADA, ATÉ QUE SEJA CONCLUÍDA.</p>
          <p>DESEJA CONTINUAR?</p>
        </div>
      </template>
    </DialogConfirmation>
    <v-overlay
      :absolute="true"
      opacity="0.95"
      color="#fff"
      :value="dialogCarga"
    >

      <v-dialog persistent content-class="dialog-carga"
        :value="dialogCarga" max-width="560"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5 font-weight-bold">Atualizando Dados</span>
          </v-card-title>
          <v-card-text class="corpo-dialog-carga">
            <v-progress-linear
              indeterminate
              color="#2196f3"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-overlay>


    <v-row class="header-integracao">
      <ButtonMain
        :desabilitar="atualizandoDados"
        :icone="atualizandoDados ? 'autorenew' : ''"
        :animacao="atualizandoDados ? 'spin' : ''"
        :primary="true"
        :text="atualizandoDados ? 'Atualizando Dados' : 'Atualizar Dados'"
        @click.native="abrirDialogAviso()"
      ></ButtonMain>

      <div class="atualizacao">
        Última atualização:
        {{ dadosCarga.ultimaAtualizacao || "-" }}
      </div>
    </v-row>

    <v-row class="corpo-integracao">
      <SheetMain titulo="Associados" width="100%">
        <template #content>
          <div class="row">
          <CardEstatisca
            icone="account"
            descricao="Total de Associados"
            cor="#7367f0"
            :valor="dadosCarga.associados"
          ></CardEstatisca>

          <CardEstatisca
            icone="account-check"
            descricao="Associados Regulares"
            cor="#28c76f"
            :valor="dadosCarga.regulares"
          ></CardEstatisca>

          <CardEstatisca
            icone="account-alert"
            descricao="Associados Irregulares"
            cor="#ea5455"
            :valor="dadosCarga.irregulares"
          ></CardEstatisca>

          </div>
        </template>
      </SheetMain>
    </v-row>
  </v-container>
</template>

<script>
  import integracaoSevice from '@/services/IntegracaoService';
  import ButtonMain from '../../../components/atoms/buttons/ButtonMain.vue';
  import ButtonIcon from '../../../components/atoms/buttons/ButtonIcon.vue';
  import SheetMain from '../../../components/organisms/SheetMain.vue';
  import CardEstatisca from '../../../components/molecules/card-estatistica/CardEstatistica.vue';
  import DialogConfirmation from '../../../components/molecules/dialogs/DialogConfirmation.vue';
  import globalMethods from '../../../mixins/globalMethods.vue'

  export default {
    components: {
      ButtonMain,
      ButtonIcon,
      SheetMain,
      CardEstatisca,
      DialogConfirmation,
    },

    data(){
      return {
        atualizandoDados: false,
        dialogAviso: false,
        dialogCarga: false,
        dadosCarga: {
          associados: 0,
          regulares: 0,
          irregulares: 0,
          ultimaAtualizacao: ''
        },
      }
    },

    created() {
      this.receberCarga()
    },

    methods: {
      abrirDialogAviso() {
        this.dialogAviso = true;
        this.dialogCarga = false;
      },

      fecharDialogAviso() {
        this.dialogAviso = false;
        this.dialogCarga = false;
      },

      confirmarDialogAviso() {
        this.dialogAviso = false;
        this.dialogCarga = true;
        this.criarCarga();
      },

      receberCarga() {
        integracaoSevice.receberCarga().then(({ data }) => {
          this.dadosCarga = {
            associados: data.totalDadosEmAssociados,
            regulares: data.associadosRegular,
            irregulares: data.associadosIrregular,
            ultimaAtualizacao:
              data.ultimaAtualizacao
                ? this.gerarHorarioCargaIntegracao(data.ultimaAtualizacao)
                : ' - '
          }
        })
      },

      criarCarga() {
        this.atualizandoDados = true;
        integracaoSevice.criarCarga().then(({ data }) => {
          this.dadosCarga = {
            associados: data.totalDadosEmAssociados || ' - ',
            regulares: data.associadosRegular || ' - ',
            irregulares: data.associadosIrregular || ' - ',
            ultimaAtualizacao:
              data.ultimaAtualizacao
                ? this.gerarHorarioCargaIntegracao(data.ultimaAtualizacao)
                : ' - ',
          }

          this.atualizandoDados = false;
          this.dialogAviso = false;
          this.dialogCarga = false;

          this.$root.vtoast.show({
            status: 'success',
            title: 'Sucesso!',
            text: data.mensagem,
          })
        })
        .catch((erro) => {
          this.atualizandoDados = false;
          this.dialogAviso = false;
          this.dialogCarga = false;

          this.$root.vtoast.show({
            status: 'error',
            text: erro.response.data.mensagemUsuario,
            title: erro.response.data.titulo,
          })
        });
      },
    },

    mixins: [ globalMethods ],
  }
</script>

<style>
  .pagina-integracao { padding-top: 2.7rem;}

  .header-integracao {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.3rem 0 1rem;
    margin-bottom: 1rem;
  }

  .corpo-integracao { padding: 1rem 0;}

  .aviso-dialog { padding: 1rem;}

  .aviso-dialog p {
    margin: 0; font-size: 1.2rem;
  }

  .corpo-dialog-carga {
    display: flex; justify-content: center;
    align-items: center; width: 100%;
  }

  @media screen and (max-width: 640px) {
    .header-integracao {
      flex-direction: column;
    }

    .header-integracao button {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
</style>
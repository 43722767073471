import axios from  'axios';

const integracaoAxios = axios.create({
  baseURL : process.env.VUE_APP_API_URL_INTEGRACAO
})

const API_INTEGRACAO = '/api-integracao';

export default {
  criarCarga() {
    const url = `${API_INTEGRACAO}/util/carga`;
    return integracaoAxios.get(url);
  },

  receberCarga() {
    const url = `${API_INTEGRACAO}/util/carregarDados`;
    return integracaoAxios.get(url);
  }
}

<template >
  <div class="card-estatistica d-flex justify-center justify-sm-start pl-0 mb-3 col-12 col-sm-6 col-md-6 col-lg-4">
    <div class="icone-container" :style="setCorBackGroundIcone" >
      <v-icon class="icone" :style="setCorIcone" large> mdi-{{ icone }}
      </v-icon>
    </div>

    <div class="informacoes">
      <div class="valor">{{ valor }}</div>
      <div class="descricao">{{ descricao }}</div>
    </div>
  </div>
</template>

<script>
export default {

  props: [
    "icone", "valor",
    "descricao", "cor"
  ],

  computed: {
    setCorIcone() {
      return {
        color: this.cor
      }
    },

    setCorBackGroundIcone() {
      return {
        backgroundColor: this.cor + '40'
      }
    }
  }

}
</script>

<style>
  .card-estatistica {
    display: flex; justify-content: flex-start;
    align-items: center; padding: 1rem;
  }

  .informacoes {
    display: flex; flex-direction: column;
    justify-content: center;
    padding-left: 1rem;
  }

  .valor {
    color: #5e5873;
    font-size: 1.5rem; margin-bottom: -0.4rem;
  }

  .descricao {
    color: #6e6b7b;
  }

  .icone-container {
    display: flex;
    justify-content: center; align-items: center;
    width: 3.5rem; height: 3.5rem;
    border-radius: 50%;
  }
</style>

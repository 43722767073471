<template >
  <v-sheet class="sheet-container"
    :color="color"
    :elevation="elevation"
    :height="height"
    :width="width"
  >
    <div class="sheet-corpo">
      <div class="header d-flex justify-center justify-sm-start">
        <slot name="header"> {{ titulo }} </slot>
      </div>

      <div class="content">
        <slot name="content"></slot>
      </div>
    </div>
  </v-sheet>
</template>

<script>

  export default {
    props: [
      "color", "elevation",
      "height", "width",
      "titulo"
    ],
  }
</script>

<style>
  .sheet-container { margin: 0 1rem; }

  .sheet-corpo {
    display: flex; flex-direction: column;
    padding: 1rem 2rem !important;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    background: #ffffff;
    width: 100%;
  }

  .header {
    font-size: 1.4rem; margin-bottom: 0.6rem;
  }

  .content {
    display: flex;
  }
</style>